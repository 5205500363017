<template>
  <div class="awards">
    <div class="awards-container pt-3">
      <div class="awards-title" @click="collapseEvent('education', !educationOpen)">
        {{ $t('edu') }}
        <img src="../assets/img/down-arrow.svg" v-if="!educationOpen" alt="photo">
        <img src="../assets/img/up-arrow.svg" v-if="educationOpen" alt="photo">
      </div>
      <transition name="slide-fade-about">
        <div v-if="educationOpen">
          <div class="awards-body mb-2">
            <div class="awards-year">2019 – 2022</div>
            <div class="awards-links">
              <div class="awards-description">
                {{ $t('master_of_mus') }}
              </div>
            </div>
          </div>
          <div class="awards-body mb-2">
            <div class="awards-year">2017 – 2019</div>
            <div class="awards-links">
              <div class="awards-description">
                {{ $t('master_of_mus_sound') }}
              </div>
            </div>
          </div>
          <div class="awards-body mb-4">
            <div class="awards-year">2012 – 2016</div>
            <div class="awards-links">
              <div class="awards-description">
                {{ $t('bel_acad') }}
              </div>
            </div>
          </div>
        </div>
      </transition>

      <div class="awards-title" @click="collapseEvent('awards', !awardsOpen)">
        {{ $t('awr_scholar') }}
        <img src="../assets/img/down-arrow.svg" v-if="!awardsOpen" alt="photo">
        <img src="../assets/img/up-arrow.svg" v-if="awardsOpen" alt="photo">
      </div>

      <transition name="slide-fade-about">
        <div v-if="awardsOpen">
          <div class="awards-body mb-2">
            <div class="awards-year">2020</div>
            <div class="awards-links">
              <a :class="{
                          'awards2020': true,
                          'text-decoration-none': awards2020.link === null,
                          'px-4': true,
                          }"
                 :style="{
                              'height': (100 / awards2020.length) + '%'
                          }"
                 target="_blank"
                 :href="awards2020.link"
                 :key="index"
                 v-for="(awards2020, index) in awards2020">
                <span>{{ $t(awards2020.name) }}</span>
              </a>
            </div>
          </div>
          <div class="awards-body mb-2">
            <div class="awards-year">2019</div>
            <div class="awards-links">
              <a :class="{
                          'awards2019': true,
                          'text-decoration-none': awards2019.link === null,
                          'px-4': true,
                          }"
                 :style="{
                              'height': (100 / awards2019.length) + '%'
                          }"
                 target="_blank"
                 :href="awards2019.link"
                 :key="index"
                 v-for="(awards2019, index) in awards2019">
                <span>{{ $t(awards2019.name) }}</span>
              </a>
            </div>
          </div>

          <div class="awards-body mb-2">
            <div class="awards-year">2018</div>
            <div class="awards-links">
              <a :class="{
                          'awards2018': true,
                          'text-decoration-none': awards2018.link === null,
                          'px-4': true,
                          }"
                 :style="{
                              'height': (100 / awards2018.length) + '%'
                          }"
                 target="_blank"
                 :href="awards2018.link"
                 :key="index"
                 v-for="(awards2018, index) in awards2018">
                <span>{{ $t(awards2018.name) }}</span>
              </a>
            </div>
          </div>

          <div class="awards-body mb-2">
            <div class="awards-year">2017</div>
            <div class="awards-links">
              <a :class="{
                          'awards2017': true,
                          'text-decoration-none': awards2017.link === null,
                          'px-4': true,
                          }"
                 :style="{
                              'height': (100 / awards2017.length) + '%'
                          }"
                 target="_blank"
                 :href="awards2017.link"
                 :key="index"
                 v-for="(awards2017, index) in awards2017">
                <span>{{ $t(awards2017.name) }}</span>
              </a>
            </div>
          </div>

          <div class="awards-body mb-2">
            <div class="awards-year">2016</div>
            <div class="awards-links">
              <a :class="{
                          'awards2016': true,
                          'text-decoration-none': awards2016.link === null,
                          'px-4': true,
                          }"
                 :style="{
                              'height': (100 / awards2016.length) + '%'
                          }"
                 target="_blank"
                 :href="awards2016.link"
                 :key="index"
                 v-for="(awards2016, index) in awards2016">
                <span>{{ $t(awards2016.name) }}</span>
              </a>
            </div>
          </div>

          <div class="awards-body mb-4">
            <div class="awards-year">2006</div>
            <div class="awards-links">
              <a :class="{
                          'awards2006': true,
                          'text-decoration-none': awards2006.link === null,
                          'px-4': true,
                          }"
                 :style="{
                              'height': (100 / awards2006.length) + '%'
                          }"
                 target="_blank"
                 :href="awards2006.link"
                 :key="index"
                 v-for="(awards2006, index) in awards2006">
                <span>{{ $t(awards2006.name) }}</span>
              </a>
            </div>
          </div>
        </div>
      </transition>

      <div class="awards-title" @click="collapseEvent('praktik', !praktikOpen)">
        {{ $t('praktikum') }}
        <img src="../assets/img/down-arrow.svg" v-if="!praktikOpen" alt="photo">
        <img src="../assets/img/up-arrow.svg" v-if="praktikOpen" alt="photo">
      </div>
      <transition name="slide-fade-about">
        <template v-if="praktikOpen">
          <div class="awards-img-container mb-4 mt-4">
            <div class="awards-img">
              <img src="../assets/img/practikum-polskie-radio.png" alt="photo">
            </div>
            <div class="awards-img">
              <img src="../assets/img/banff-centre.png" alt="photo">
            </div>
            <div class="awards-img">
              <img src="../assets/img/br-klassik.png" alt="photo">
            </div>
            <div class="awards-img">
              <img src="../assets/img/emil-berliner-studios.png" alt="photo">
            </div>
          </div>
        </template>
      </transition>

      <img class="img-fluid" src="../assets/contacts-hor.png" alt="">
    </div>
  </div>
</template>

<script>
export default {
  name: "Awards",
  data() {
    return {
      educationOpen: false,
      awardsOpen: false,
      praktikOpen: false,
      imageLoading: true,
      awards2020: [
        {
          link: 'https://www.merging.com/news/press-releases/aes-virtual-vienna-student-prize-award-from-merging',
          name: 'silver_2020',
        },
        {
          link: 'https://www.merging.com/news/press-releases/aes-virtual-vienna-student-prize-award-from-merging',
          name: 'gold_2020',
        },
      ],
      awards2019: [
        {
          link: null,
          name: 'prize_mon',
        },
        {
          link: null,
          name: 'daad',
        },
      ],
      awards2018: [
        {
          name: 'bronze_2018',
          link: 'https://tonmeistertagung.com/en/2018/competition/',
        },
        {
          name: 'aes_2018',
          link: 'http://www.swedienscholarship.org/',
        },
        {
          name: 'bronze_2018_milan',
          link: 'https://www.aes.org/students/blog/2018/8/aes144-student-recording-competition-interview-5',
        },
        {
          name: 'cirmmt',
          link: 'https://www.cirmmt.org/research',
        },
      ],
      awards2017: [
        {
          name: 'mon_2017',
          link: null,
        },
        {
          name: 'polonia_2017',
          link: 'https://www.nck.pl/en/dotacje-i-stypendia/stypendia/programy/gaude-polonia',
        },
      ],
      awards2016: [
        {
          name: 'paris_2016',
          link: 'https://www.aes.org/students/blog/2016/8/aes140-student-recording-competition-interview-ksenia',
        },
      ],
      awards2006: [
        {
          name: 'minsk_2006',
          link: null
        }
      ]
    }
  },
  methods: {
    collapseEvent(title, val) {
      if (title === 'education') {
        this.educationOpen = val;
      }
      if (title === 'awards') {
        this.awardsOpen = val;
      }
      if (title === 'praktik') {
        this.praktikOpen = val;
      }
    }
  }
}
</script>

<style lang="scss">
.slide-fade-about-enter-active,
.slide-fade-about-leave-active
{
  transition: all .5s ease;
}
.slide-fade-about-enter-from,
.slide-fade-about-leave-to
{
  opacity: 0;
}
.hidden {
  transition: 2s ease-in-out;
  height: 0;
}
.active {
  transition: 2s ease-in-out;
  height: 100%;
}
.collapse {
  transition: 1s ease;
}
.container-fluid .router-view {
  max-height: 100%;
}
.awards {
  display: flex;
  flex-direction: column;
  align-items: center;
  //background: url("../assets/contacts-hor.png");
}
img {
  max-width: 100%;
}
.awards-container {
  // background-color: #607D8B;
  position: relative;
  padding-left: 6rem;
  padding-right: 6rem;
  width: 100%;
  .awards-container-animation {
    transition: max-height 0.5s ease;
    max-height: 0;
    &.active {
      //transition: max-height 1s ease-in !important;
      max-height: 400px !important;
    }
  }
  .awards-title {
    font-size: 20px;
    margin-bottom: 1.5rem;
    cursor: pointer;
  }
  .awards-img-container {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    grid-gap: 20px;
    align-items: center;
    .awards-img {
      img {
        height: 40px;
        max-width: 50%;
        @media screen and (max-width: 800px) {
          max-width: 100%;
        }
      }
    }
  }
  .awards-body {
    display: grid;
    grid-template-columns: 200px 1fr;
    align-items: center;
    .awards-year {
      font-size: 20px;
    }
    .awards-links {
      display: flex;
      flex-direction: column;
      text-align: left;
      a {
        text-align: left;
      }
      .awards-description {
        padding: 0 24px;
      }
      .awards-description,
      a {
        padding-bottom: 10px;
        color: #332631;
        font-size: 16px;
        &:last-child {
          padding-bottom: 0;
        }
      }
    }
  }
  @media screen and (max-width: 800px) {
    .awards-body {
      grid-template-columns:  1fr;
    }
    a {
      text-align: center;
    }
  }
}
@media screen and (max-width: 800px) {
  .awards-container {
    padding: 0 10px;
  }
}
</style>